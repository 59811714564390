import {
  Announcement,
  Banner,
  Box,
  Container,
  Heading,
  Helmet,
  Intro,
  Layout,
  Link,
  Row,
  Section,
  Theme,
  TickListItem,
  routesObject
} from "@life-without-barriers/react-components"
import { PageAnnouncement, Site } from "@life-without-barriers/gatsby-common"

import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { graphql } from "gatsby"
import { HippyProgramForParentsAndYoungstersForm } from "@life-without-barriers/shared-contact-form"

const { youthFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    announcement: PageAnnouncement
  }
}

const HippyProgramForParentsAndYoungstersPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    announcement
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`HIPPY - Early literacy program for 3-5 year olds | ${siteMetadata.title}`}
        description="HIPPY is a free home mentoring program to help parents and their little ones prepare for formal schooling in the area of Whittlesea, Victoria and Deception Bay, Queensland."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout
        theme={youthFull}
        fixedCTAContact={
          <HippyProgramForParentsAndYoungstersForm formId="cyf-hippy-mobile-cta" />
        }
        fixedCTAPhoneNumber="1800935483"
      >
        <Banner
          title="Home Interaction Program for Parents and Youngsters (HIPPY)"
          image={bannerImage}
          breadcrumbs={[
            routesObject.home,
            {
              to: "/services",
              text: "Services",
              title: "Return to services page",
              rooted: true
            },
            {
              to: "/services/child-youth-and-family",
              text: "Child, youth and family",
              title: "Return to parent page"
            }
          ]}
        />
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              {announcement && (
                <Announcement insideBoxComponent {...announcement} />
              )}
              <Intro
                text={`Life Without Barriers co-ordinates the HIPPY program (Home Interaction Program for Parents and Youngsters) for Rockhampton (QLD), Deception Bay (QLD) and Whittlesea (VIC).`}
              />
              <Heading size={2} topMargin="standard">
                About HIPPY
              </Heading>
              <p>
                HIPPY is a two-year, home-based, early learning and parenting
                program that empowers parents to be their child&apos;s first
                teacher and supports their transition to school.
              </p>
              <p>
                As part of the program, parents receive free resources, activity
                packs and storybooks to assist in actively playing and learning
                with their child.
              </p>
              <p>
                Each pack has activities for you to do with your child and ideas
                so that learning can happen anywhere at any time.
              </p>
              <p>
                Parents are paired up with a HIPPY mentor who will visit them in
                their home fortnightly in age three and weekly in age four to
                practice the activities with them.
              </p>
              <Heading size={2} topMargin="standard">
                What is a HIPPY mentor?
              </Heading>
              <p>
                Mentors are parents who are also completing the program with
                their child or have recently graduated from it.
              </p>
              <p>
                They are trained in the program&apos;s activities and will
                practice them with parents at each home visit. Children do not
                have to be present when the mentor visits.
              </p>
              <p>
                Mentors can be met at home or somewhere else, like a park or a
                library. After this, parents do the activities with their child.
              </p>
            </Box>
            <Box className="w-100 w-third-l pt4 pt0-l">
              <HippyProgramForParentsAndYoungstersForm formId="cyf-hippy" />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Heading size={2}>Who can apply for the HIPPY program?</Heading>
                <ul className="mt4 pl1">
                  {[
                    "Families who live in Rockhampton, Deception Bay and Whittlesea, who have a child turning 3 before the cut-off date (April 30th in Whittlesea or June 30th for Rockhampton and Deception Bay), who are willing to work with a HIPPY mentor and spend fifteen minutes a day doing fun activities with their child are welcome to apply.",
                    "Preference is given to families who identify as Aboriginal and/or Torres Strait Islander, or those on a health care card.",
                    "A commitment of two years is required."
                  ].map((text, i) => (
                    <TickListItem
                      key={i}
                      text={text}
                      className={i === 0 ? "w-90-ns" : "mt3 w-90-ns"}
                      textClasses="fw5 pt0"
                    />
                  ))}
                </ul>
              </Box>
            </Row>
          </Container>
        </Section>

        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>HIPPY is fun and it&apos;s free</Heading>
              <p>
                If you live in Rockhampton, Deception Bay or Whittlesea, contact
                Life Without Barriers now for more information of how this
                program will prepare children for formal school. Simply fill out
                the form on this page and a staff member will be in contact
                shortly.
              </p>
              <div className="mt5">
                <small>
                  The Brotherhood of St Laurence holds the licence to operate
                  HIPPY in Australia. HIPPY is funded by the Australian
                  Government through the Department of Social Services. You can
                  see the{" "}
                  <Link
                    className="link color-lwb-black underline fw3"
                    to="https://hippyaustralia.bsl.org.au/"
                  >
                    full program&apos;s website here.
                  </Link>
                </small>
              </div>
            </Box>
          </Row>
        </Container>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(relativePath: { regex: "/hippy-new-banner-2022.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    announcement: contentfulAnnouncement(
      page: { eq: "hippy-home-interaction-program-for-parents-and-youngsters" }
    ) {
      ...ContentfulAnnouncement
    }
  }
`

export default HippyProgramForParentsAndYoungstersPage
